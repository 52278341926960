import React from 'react'
import styled, { css } from 'styled-components'

import Content from './Content'

const Wrapper = styled.div`
  ${props =>
    props.hero &&
    css`
      margin-bottom: 16.666666vh;
    `}
`

const Title = styled.h1`
  font-size: 80px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: bold;

  @media (max-width: 860px) {
    font-size: 60px;
  }

  @media (max-width: 480px) {
    font-size: 42px;
  }

  ${props =>
    props.hero &&
    css`
      font-size: 120px;

      @media (max-width: 860px) {
        font-size: 80px;
      }

      @media (max-width: 480px) {
        font-size: 62px;
      }
    `}
`

const Meta = styled.div`
  font-size: 30px;
  margin-bottom: 0.5rem;

  @media (max-width: 480px) {
    font-size: 26px;
  }
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 0;

  ${props =>
    props.hero &&
    css`
      min-height: 50vh;
    `}
`

const Intro = ({ meta, main, hero }) => (
  <Wrapper>
    <Content>
      <Inner hero={hero}>
        <Meta>{meta}</Meta>
        <Title hero={hero}>{main}</Title>
      </Inner>
    </Content>
  </Wrapper>
)

export default Intro
